import styled from "styled-components";

export const ContainerModal = styled.div`
  display: block;
  background-color: red;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 90px;
  height: 90px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.65rem 1rem;
`;

export const ButtonCloseModal = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: 0;
  padding: 0;
  margin-right: 15px;

  svg {
    display: block;
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 990px) {
    margin-right: 0;
  }
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: var(--gray500);
  margin: 0;
  margin-left: 35px;

  @media screen and (max-width: 990px) {
    margin-left: 10px;
  }
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding: 0 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 990px) {
    margin-top: 1.35rem;
  }
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: var(--purple400);
  margin: 0;
`;

export const Subtitle = styled.h3`
  font-size: 1rem;
  font-weight: 900;
  text-align: center;
  color: var(--purple400);
  margin: 0;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--gray400);
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  margin: 0;
  padding-bottom: 15px;
  margin-bottom: 12px;
  border-bottom: 1px solid var(--gray400);
`;

export const TitleForm = styled.h2`
  font-size: 1rem;
  font-weight: 900;
  color: var(--orange600);
  text-align: center;
  margin: 0;
  font-family: "Poppins";
`;

export const LinkRestPassword = styled.a`
  display: block;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--purple600);
  text-decoration: none;
  margin: 5px 0;
  cursor: pointer;
`;

export const SubmitForm = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 5px auto 10px;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const Message = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;

  svg {
    path {
      fill: var(--gray500);
    }
  }

  p {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.125rem;
    color: var(--gray500);
    margin: 0;
  }
`;

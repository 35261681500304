import styled from "styled-components";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  max-height: 100%;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.65rem 1rem;
`;

export const ModalHeaderTilte = styled.h2`
  font-size: 1rem;
  font-weight: 900;
  color: var(--purple400);
  margin: 0;
`;

export const ModalBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  padding: 0 1rem;
  margin-top: 0.65rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin: 0;
  margin-bottom: 12px;
`;

export const ModalFooter = styled.div`
  padding: 1rem;
`;

export const ButtonSelectDate = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

import { useState, useEffect } from "react";
import Modal from "react-modal";
import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconWarning } from "../../assets/icones/icons dense (24px)/aviso - yellow.svg";
import { ReactComponent as IconError } from "../../assets/icones/icons dense (24px)/aviso - red.svg";
import { ReactComponent as IconClose } from "../../assets/icones/icons minimal (20px)/close.svg";

import {
  ButtonCloseModal,
  ButtonSelectDate,
  ButtonText,
  ContainerDates,
  ContainerWarnning,
  Content,
  ContentCalendar,
  ContentInfos,
  CustomCalendar,
  DateInput,
  DateLabel,
  FooterMessage,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  TextInfo,
  TextWarnning,
} from "./styles";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { DataResponse, ModalDateAvailableProps } from "./types";
import { formatDate } from "../../utils";
import useCreateToken from "../../hooks/createToken";
import { api } from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalPolicy } from "../modalPolicy";
import { CalendarTileProperties, ViewCallbackProperties } from "react-calendar";
import { City } from "../searchDesktop/types";
import { useUrlParams } from "../../hooks/useUrlParams";

export const ModalDateAvailable = ({
  modalIsOpen,
  setIsOpen,
  hotel,
  dateStart,
}: ModalDateAvailableProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");
  const [warn, setWarn] = useState(true);
  const [dateIn, setDateIn] = useState<undefined | Date>();
  const [dateOut, setDateOut] = useState<undefined | Date>();
  const [step, setStep] = useState<1 | 2>(1);
  const [message, setMessage] = useState<null | string>(null);
  const [days, setDays] = useState<null | string>(null);
  const { tokenApi, createToken } = useCreateToken();
  const [loading, setLoading] = useState<boolean>(true);
  const [toggleModalPolicy, setToggleModalPolicy] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [datesAvailable, setDatesAvailable] = useState<Date[]>([]);
  const [activeStartDate, setActiveStartDate] = useState<Date>(
    dateStart || new Date()
  );
  const [showErrorCalendar, setShowErrorCalendar] = useState(false);
  const { urlParams } = useUrlParams();

  const sixDaysBeforeTheStartOfTheMonth = (date: Date): Date => {
    const copy = new Date(date);
    copy.setDate(1);
    copy.setDate(copy.getDate() - 6);
    return copy;
  };

  const selectDay = (value: Date) => {
    if (step === 1) {
      setDateIn(value);
      localStorage.setItem("dataInicial", formatDate(value)!);
      if (urlParams.tipoUsuario) {
        setDateOut(value);
        localStorage.setItem("dataFinal", formatDate(value)!);
        setStep(2);
        return;
      }

      setDateOut(undefined);
      setStep(2);
    }

    if (step === 2) {
      if (dateIn !== undefined && value < dateIn) {
        setDateOut(dateIn);
        localStorage.setItem("dataFinal", formatDate(dateIn)!);
        setDateIn(value);
        localStorage.setItem("dataInicial", formatDate(value)!);
        setStep(1);
        return;
      }

      setDateOut(value);
      localStorage.setItem("dataFinal", formatDate(value)!);
      setStep(1);
    }
  };
  const handleToggleModalPolicy = () => {
    setToggleModalPolicy(!toggleModalPolicy);
  };

  const save = () => {
    if (dateIn && dateOut) {
      const dataInicial = formatDate(dateIn) || "";
      const dataFinal = formatDate(dateOut) || "";

      localStorage.setItem("dataInicial", dataInicial);
      localStorage.setItem("dataFinal", dataFinal);
      const token = localStorage.getItem("token");
      if (!!token) {
        setLoading(true);

        api
          .post(`wsCoobrastur/ConsultaHoteis.asmx/C20_1`, {
            token: tokenApi,
            consulta: hotel?.name,
            iata: false,
          })
          .then(async (response) => {
            let place = response.data[0];
            if (place?.CodigoID !== hotel?.hotCodigo) {
              const addressArray = hotel.address.split(",");
              const resp2 = await api.post(
                `wsCoobrastur/ConsultaHoteis.asmx/C20_1`,
                {
                  token: tokenApi,
                  consulta:
                    addressArray[addressArray.length - 2].trim() ||
                    addressArray[addressArray.length - 1].trim(),
                  iata: false,
                }
              );
              const found = resp2?.data?.find(
                (element: City) => element.CodigoID === hotel.hotCodigo
              );
              if (found) {
                place = found;
              } else {
                throw new Error("NOT FOUND HOTEL");
              }
            }
            setLoading(false);
            setIsOpen(false);
            navigate(`${process.env.PUBLIC_URL}/hospedagens`, {
              state: { data: { ...state.data, dateIn, dateOut, place } },
            });
          })
          .catch((err) => {
            console.error(err);
            setShowErrorCalendar(true);
            setLoading(false);
          });
      } else {
        setLoading(false);
        console.error({ component: "modalDateAvailable", erro: "NO TOKEN" });
      }
    }
  };

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    if (step === 1) {
      setMessage("Escolha sua <strong>data de entrada</strong>");
    }

    if (step === 2) {
      setMessage("Escolha sua <strong>data de saída</strong>");
    }
  }, [step]);

  useEffect(() => {
    if (dateIn && dateOut) {
      const diffInMs = dateOut.getTime() - dateIn.getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (urlParams.tipoUsuario) {
        const correctedDifference = diffInDays === 0 ? 1 : diffInDays;
        setMessage(null);
        setDays(`Estadia de ${correctedDifference} diária(s)`);
        return;
      }

      setMessage(diffInDays < 2 ? "Estadia minima de 2 dias" : null);
      setDays(`Estadia de ${diffInDays} diária(s)`);
    }
  }, [dateIn, dateOut, urlParams.tipoUsuario]);

  // when activeStartDate changes, call C22
  useEffect(() => {
    if (modalIsOpen && tokenApi) {
      setShowErrorCalendar(false);
      setLoading(true);
      const data = {
        token: tokenApi,
        mes: sixDaysBeforeTheStartOfTheMonth(activeStartDate)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join(""),
        hotCodigo: hotel.hotCodigo,
      };

      api
        .post(`/wsCoobrastur/ConsultaHoteis.asmx/C22_1`, {
          dados: JSON.stringify(data),
        })
        .then((response) => {
          const { data } = response;

          const listDatesAvailable = data
            .filter((date: DataResponse) => date.cor === "True")
            .map((date: DataResponse) => {
              const dateString = date.data.split("/").reverse().join("/");
              return new Date(dateString);
            });
          setDatesAvailable(listDatesAvailable);
          // console.log("C22_1 DateRage", listDatesAvailable);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setShowErrorCalendar(true);
          setDateIn(undefined);
          setDateOut(undefined);
          setStep(1);
          setLoading(false);
        });
    }
  }, [activeStartDate, modalIsOpen, tokenApi, hotel.hotCodigo]);

  const disabledDates = ({ date }: CalendarTileProperties) => {
    if (loading) return true;
    const today = new Date();
    today.setTime(date.getTime());
    const defaultReturn =
      !datesAvailable.find((value) => value.valueOf() === date.valueOf()) ||
      date < today;
    if (!dateIn || (!!dateIn && !!dateOut)) return defaultReturn;

    const diffInMs = Math.abs(date.getTime() - dateIn.getTime());
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return defaultReturn || diffInDays < 2;
  };

  const handleChangeMonth = (e: ViewCallbackProperties) => {
    setActiveStartDate(e.activeStartDate);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={modalIsOpen}
      style={{
        content: {
          width: "100%",
          maxWidth: mobile ? "100%" : "592px",
          height: mobile ? "100%" : "485px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: mobile ? "0" : "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ButtonCloseModal
            type="button"
            title="fechar"
            onClick={() => setIsOpen(!modalIsOpen)}
          >
            <IconFechar />
          </ButtonCloseModal>
          <ModalHeaderTilte>
            {hotel.name}
            {mobile && (
              <TextInfo>
                Clique em uma data disponível no calendário para refazer a
                pesquisa.
              </TextInfo>
            )}
          </ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Content>
            <ContentInfos>
              <ContainerDates>
                <div>
                  <DateLabel>Entrada</DateLabel>
                  <DateInput>{formatDate(dateIn) || "-"}</DateInput>
                </div>
                <div>
                  <DateLabel>saída</DateLabel>
                  <DateInput>{formatDate(dateOut) || "-"}</DateInput>
                </div>
              </ContainerDates>

              {!mobile && (
                <TextInfo>
                  Clique em uma data disponível no calendário para refazer a
                  pesquisa.
                </TextInfo>
              )}

              <ContainerWarnning open={warn}>
                <IconWarning />
                <div>
                  <TextWarnning>
                    Reservas feitas pela Coob+ precisam ser feitas com 30 dias
                    de antecedência. Mas não se preocupe, se sua reserva for
                    para entrada com data inferior a 30 dias, você poderá
                    solicitar disponibilidade de vaga aos hotéis diretamente
                    pelo site da Coob+. Mas, essa disponibilidade ficará a
                    critério do hotel aceitar ou não e a Coob+ não poderá
                    interferir.
                  </TextWarnning>
                  <ButtonText onClick={handleToggleModalPolicy}>
                    Dúvida, leia mais detalhes aqui
                  </ButtonText>
                  {toggleModalPolicy && (
                    <ModalPolicy
                      isOpen={toggleModalPolicy}
                      onClose={handleToggleModalPolicy}
                    />
                  )}
                </div>
                <IconClose onClick={() => setWarn(!warn)} />
              </ContainerWarnning>
            </ContentInfos>

            <ContentCalendar loading={loading}>
              {/* When  CustomCalendar reRender lost range select, avoid rerender*/}
              {!showErrorCalendar ? (
                <>
                  <span className="spining"></span>
                  <CustomCalendar
                    activeStartDate={activeStartDate}
                    selectRange={true}
                    onClickDay={selectDay}
                    onActiveStartDateChange={handleChangeMonth}
                    tileDisabled={disabledDates}
                    goToRangeStartOnSelect={true}
                    next2AriaLabel=""
                    next2Label={null}
                    prev2AriaLabel=""
                    prev2Label={null}
                  />
                </>
              ) : (
                <ContainerWarnning open={showErrorCalendar}>
                  <IconError />
                  <TextWarnning>
                    Ops. Algo Deu errado! Por favor tente novamente.
                  </TextWarnning>
                  <IconClose
                    onClick={() => {
                      setShowErrorCalendar(false);
                      setIsOpen(false);
                    }}
                  />
                </ContainerWarnning>
              )}
            </ContentCalendar>
          </Content>
        </ModalBody>

        <ModalFooter>
          <FooterMessage>
            {message !== null && (
              <span dangerouslySetInnerHTML={{ __html: message }}></span>
            )}

            {message === null && days !== null && (
              <span
                className="success"
                dangerouslySetInnerHTML={{ __html: days }}
              ></span>
            )}
          </FooterMessage>

          <ButtonSelectDate disabled={message !== null} onClick={save}>
            Selecionar datas
          </ButtonSelectDate>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

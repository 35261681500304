import Modal from "react-modal";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import {
  ButtonSelectDate,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTilte,
  Text,
} from "./styles";

type ModalPolicyProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const ModalPolicy = ({ isOpen, onClose }: ModalPolicyProps) => {
  const mobile = useMediaQuery("(max-width: 990px)");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={{
        content: {
          width: "95%",
          maxWidth: "592px",
          height: mobile ? "571px" : "485px",
          padding: "0",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
          zIndex: 999,
          borderRadius: "20px",
        },
        overlay: {
          zIndex: 999,
          backgroundColor: "rgba(0,0,0,0.48)",
        },
      }}
    >
      <ModalContent>
        <ModalHeader>
          <ModalHeaderTilte>Sobre nossa política de reservas</ModalHeaderTilte>
        </ModalHeader>

        <ModalBody>
          <Text>
            As solicitações de reserva pela Coob+ precisam ser feitas com o
            prazo mínimo de 30 dias de antecedência devido ao nosso acordo com
            os nossos hotéis credenciados.
          </Text>
          <Text>
            Por essa razão que conseguimos disponibilizar os nossos planos com
            mensalidades que chegam até 60% de desconto em relação a tarifa
            balcão dos hotéis.
          </Text>
          <Text>
            Mas não se preocupe, se sua reserva for para entrada com data
            inferior a 30 dias, você poderá solicitar disponibilidade de vaga
            aos hotéis diretamente pelo nosso site.
          </Text>
          <Text>
            Mas, essa disponibilidade ficará a critério do hotel aceitar ou não
            e a Coob+ não poderá interferir.
          </Text>
          <Text>
            Como essa disponibilidade com menos de 30 dias não é contemplada
            pelo plano, você precisará pagar uma taxa extra por estar fora do
            prazo. Segue abaixo os valores cobrados:
          </Text>
          <Text>Planos Go : R$ 25 por diária</Text>
          <Text>Planos Vip/Master, Gold e Diamante : R$ 50 por diária</Text>
        </ModalBody>

        <ModalFooter>
          <ButtonSelectDate onClick={onClose}>FECHAR</ButtonSelectDate>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

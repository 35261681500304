import { Fragment, useEffect, useState } from "react";
import {
  Container,
  Dayli,
  Divider,
  Input,
  Label,
  Loading,
  TopInfo,
  Validate,
  WrapperInfo,
  WrapperLabel,
} from "./styles";

import IconTicket from "./assets/ticket.svg";
import { TicketProps, TesteProps } from "./types";

const Tickets = ({ room, tickets, update, loading }: TicketProps) => {
  const [ticketList, setTicketList] = useState<TesteProps[] | undefined>([]);
  const [descriptionList, setDescriptionList] = useState<TesteProps[]>([]);

  const handleChange = async (option: TesteProps) => {
    if (ticketList) {
      const cloneList = [...ticketList];
      const newList = cloneList.map((element) => {
        if (option.tktCodigo === element.tktCodigo) {
          if (!option.checked) {
            return { ...element, checked: true };
          }
          if (option.checked) {
            return { ...element, checked: false };
          }
        }
        return { ...element };
      });
      update(newList, room);
    }
  };

  const FormatNameTicket = (item: string) => {
    const firstSeparator = item.split("(");

    return firstSeparator[0];
  };

  const FormatValidateTicket = (item: string) => {
    const firsSeparator = item.split("Plano");
    const secondSeparator = firsSeparator[1].split("-");

    return secondSeparator;
  };

  useEffect(() => {
    const ticketsList = tickets?.filter((element) => element.plano !== null);
    const description = tickets?.filter((element) => element.plano === null);

    setTicketList(ticketsList);

    if (description && description?.length >= 0) {
      setDescriptionList(description);
    }
  }, [tickets]);

  if (loading) {
    return (
      <Loading active={loading}>
        <span></span>
      </Loading>
    );
  }

  return (
    <Fragment>
      {!ticketList || ticketList?.length <= 0 ? (
        <div
          style={{
            textAlign: "center",
            color: "var(--pink600)",
            fontWeight: "bold",
          }}
        >
          Você não possui e-tickets disponiveis para selecionar esta reserva mas
          você pode pagar por esta reserva sem usar e-tickets
        </div>
      ) : (
        ticketList?.map((option) => (
          <Container key={option.tktCodigo}>
            <Input
              type="checkbox"
              id={option.plano}
              name={option.Texto}
              onChange={() => handleChange(option)}
              checked={option.checked}
              isUsed={option.Editar}
              disabled={option.Editar === 0 ? true : false}
            />
            <Label sugestion={option.sugestao} isUsed={option.Editar}>
              <TopInfo>
                <WrapperLabel>
                  <div style={{ marginTop: "-3px" }}>
                    <img src={IconTicket} alt="icon-ticket" />
                  </div>

                  <Divider />
                  <WrapperInfo>
                    Plano {FormatNameTicket(option.plano)}
                  </WrapperInfo>
                </WrapperLabel>
                <Dayli> {option.tktQtdeDiarias_tkt} diárias</Dayli>
              </TopInfo>
              <Validate>
                <span>{FormatValidateTicket(option.Descricao)}</span>
                <span>#{option.tktCodigo}</span>
              </Validate>
            </Label>
          </Container>
        ))
      )}
      {descriptionList.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "var(--white)",
          }}
        >
          {descriptionList.map((item) => (
            <span
              key={item.Descricao}
              style={{
                width: "100%",
                padding: 4,
                textAlign: "center",
                marginTop: 10,
                backgroundColor: "var(--green600)",
                fontSize: "0.9rem",
                fontWeight: "bold",
                borderRadius: "8px",
              }}
            >
              {item.Descricao}
            </span>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default Tickets;

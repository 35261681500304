import { useState, useEffect, useRef, memo } from "react";
import CoupleImg1 from "./assets/img-maefilha.png";
import CoupleIm2 from "./assets/img-casal.png";
import "react-calendar/dist/Calendar.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ListPromotionProps } from "./types";
import PrevArrow from "../../components/prevArrow";
import NextArrow from "../../components/nextArrow";
import { api } from "../../services";
import {
  CarrouselItems,
  partnerList,
  settingsCarouselBanner,
  settingsCarouselOffers,
  settingsLastSlider,
} from "./constants";

import {
  Container,
  Section,
  WrapperCarousel,
  TitleSection,
  WrapperItensSection,
  WrapperPhotoSection,
  ImgCouple1,
  CardPromotion,
  BadgeCard,
  TitleCard,
  DescriptioncCard,
  BtnSeeOffers,
  BtnSubscription,
  WrapperCarouselBenefits,
  WrapperContent,
  TitleContent,
  WrapperItensContent1,
  WrapperItensContent2,
  ImgCouple2,
  WrapperItensContent1Mobile,
  ImageCardPromotion,
  ImgItem,
  SectionCarouselPromo,
  ImgItemMobile,
  TitleCoob,
  ColumnCentered,
} from "./styles";
import BeneFitys from "../../components/benefitys";
import { SearchDesktop } from "../../components/searchDesktop";
import ModalPromotion from "../../components/ModalPromotion";
// import PopUp from "./PopUp";
import PopCookie from "./PopCookie";
import Cookies from "js-cookie";
import useCreateToken from "../../hooks/createToken";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import PartnerSection from "./PartnerSection";
import TestimonialSection from "./TestimonialSection";
import { CoobrasturGroupSection } from "./CoobrasturGroupSection";

const formatPlanImg = (plan: string) => {
  if (plan === "Diamante") {
    // return `https://www.coobmais.com.br/images/planos/3.png`;
    return `https://www.coobmais.com.br/images/planos/novos/diamante.png`;
  }

  if (plan === "Convencional") {
    // return `https://www.coobmais.com.br/images/planos/1.png`;
    return `https://www.coobmais.com.br/images/planos/novos/vipmaster.png`;
  }

  if (plan === "Gold") {
    // return `https://www.coobmais.com.br/images/planos/2.png`;
    return `https://www.coobmais.com.br/images/planos/novos/goldvip.png`;
  }

  // return `https://www.coobmais.com.br/images/planos/5.png`;
  return `https://www.coobmais.com.br/images/planos/novos/govip.png`;
};

function Home() {
  const [listPromotion, setListPromotion] = useState<ListPromotionProps[]>([]);
  const [itemPromotion, setItemPromotion] = useState<ListPromotionProps>();
  const [openModalPromotion, setOpenModalPromotion] = useState<boolean>(false);
  const [, setPopUpIsOpen] = useState<boolean>(true);
  const [acceptedCookie, setAcceptedCookie] = useState<boolean>(false);
  const slider = useRef<any>(null);
  const { tokenApi, createToken } = useCreateToken();
  const CookieAccepted = Cookies.get("aceiteCookies");

  const handlePrevSlider = () => {
    if (slider.current !== null) {
      slider.current.slickPrev();
    }
  };

  const handleNextSlider = () => {
    if (slider.current !== null) {
      slider.current.slickNext();
    }
  };

  const handleToggleModalPromotion = () => {
    setOpenModalPromotion(!openModalPromotion);
  };

  const handleSeePromotion = (item: ListPromotionProps) => {
    setItemPromotion(item);

    handleToggleModalPromotion();
  };

  useEffect(() => {
    // const idUser = localStorage.getItem("assnic") || "";
    // api
    //   .post(`/wsCoobrastur/ConsultaHoteis.asmx/P1_1`, { nic: idUser })
    api
      .post(`/wsCoobrastur/ConsultaHoteis.asmx/P1_1`, { nic: "" })
      .then((resp) => {
        if (resp.status === 200) {
          setListPromotion(resp.data);
          return;
        }
      })
      .catch((error) => {
        console.error({
          component: "home",
          content: "promotion",
          error: error.message,
        });
      });
  }, []);

  useEffect(() => {
    setPopUpIsOpen(true);
  }, []);

  useEffect(() => {
    if (CookieAccepted) {
      setAcceptedCookie(true);
      return;
    }
  }, [CookieAccepted, acceptedCookie]);

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    localStorage.setItem("token", tokenApi);
  }, [tokenApi]);

  const mobile = useMediaQuery("(max-width: 500px)");

  return (
    <Container>
      {openModalPromotion && (
        <ModalPromotion
          item={itemPromotion}
          onClose={handleToggleModalPromotion}
        />
      )}
      {/* {popUpIsOpen && <PopUp close={() => setPopUpIsOpen(!popUpIsOpen)} />} */}
      {!acceptedCookie && <PopCookie click={() => setAcceptedCookie(true)} />}
      <WrapperCarousel className="WrapperCarousel">
        <Slider {...settingsCarouselBanner}>
          {/* {CarrouselItems.map((item) => (
            <div key={item.id}>
              {item.id === 4 && (
                <div>
                  <ImgItem src={item.img} alt="image-banner" />
                  <ImgItemMobile src={item.imgMobile} alt="image-banner" />
                </div>
              )}
              {item.id !== 4 && (
                <a href={item.link} target="_blanck">
                  <ImgItem src={item.img} alt="image-banner" />
                  <ImgItemMobile src={item.imgMobile} alt="image-banner" />
                </a>
              )}
            </div>
          ))} */}
          {CarrouselItems.filter(
            (item) => item.device === (mobile ? "MOBILE" : "DESKTOP")
          ).map((item) => (
            <div key={item.id} className="teste">
              <a href={item.link} target="_blanck">
                <ImgItem src={item.img} alt="image-banner" />
                <ImgItemMobile src={item.img} alt="image-banner" />
              </a>
            </div>
          ))}
        </Slider>
      </WrapperCarousel>
      <SearchDesktop />
      <Section>
        <TitleCoob>Coob+</TitleCoob>
        <TitleSection>
          Conheça as vantagens das diárias de hotéis por assinatura coob+
        </TitleSection>
        <WrapperItensSection>
          <WrapperPhotoSection>
            <ImgCouple1 src={CoupleImg1} alt="casal-foto" />
          </WrapperPhotoSection>
          <ColumnCentered>
            <BeneFitys />
            <BtnSubscription
              href="https://www.coobmais.com.br/lp/planos/"
              target="_blank"
            >
              Assine já
            </BtnSubscription>
          </ColumnCentered>
        </WrapperItensSection>
      </Section>
      <Section id="Promoções">
        <TitleSection>HOTÉIS COM PROMOÇÕES</TitleSection>
        <SectionCarouselPromo>
          <WrapperCarouselBenefits>
            <PrevArrow onClick={handlePrevSlider} />
            <NextArrow onClick={handleNextSlider} />
            <Slider ref={slider} {...settingsCarouselOffers}>
              {listPromotion.map((item) => (
                <CardPromotion key={item.id}>
                  <img
                    src={formatPlanImg(item.nome_plano)}
                    alt="rede"
                    style={{ position: "absolute", top: 16, left: 12 }}
                    // style={{
                    //   position: "absolute",
                    //   marginTop: "-440px",
                    //   marginLeft: "-180px",
                    // }}
                    width={item.nome_plano !== "Convencional" ? 40 : 80}
                    height="auto"
                  />
                  <ImageCardPromotion
                    img={
                      item.nome_hotel === "RIO DAS GARÇAS ECO RESORT"
                        ? `https://www.coobmais.com.br/images/hotel/${item.hotCodigo}/02.jpg`
                        : `https://www.coobmais.com.br/images/hotel/${item.hotCodigo}/01.jpg`
                    }
                  />
                  <BadgeCard>{item.cidade}</BadgeCard>
                  <TitleCard>{item.nome_hotel.toLowerCase()}</TitleCard>
                  <DescriptioncCard>{item.ofeTitulo}</DescriptioncCard>
                  <BtnSeeOffers onClick={() => handleSeePromotion(item)}>
                    Ver promoção
                  </BtnSeeOffers>
                  <BtnSubscription
                    href="https://www.coobmais.com.br/lp/planos/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Assine
                  </BtnSubscription>
                </CardPromotion>
              ))}
            </Slider>
          </WrapperCarouselBenefits>
        </SectionCarouselPromo>
      </Section>
      <Section id="Indique+">
        <WrapperContent>
          <WrapperItensContent1>
            <TitleContent>Indique e escolha seu prêmio!</TitleContent>
            <BtnSubscription
              href="https://www.coobmais.com.br/lp/indiquemais/"
              target="_blank"
            >
              clique e indique
            </BtnSubscription>
          </WrapperItensContent1>
          <WrapperItensContent1Mobile>
            <TitleContent>Indique e escolha seu prêmio!</TitleContent>
          </WrapperItensContent1Mobile>
          <ImgCouple2 src={CoupleIm2} alt="casal-foto" />
          <WrapperItensContent1Mobile>
            <BtnSubscription
              href="https://www.coobmais.com.br/lp/indiquemais/"
              target="_blank"
            >
              clique e indique
            </BtnSubscription>
          </WrapperItensContent1Mobile>
          <WrapperItensContent2>
            <TitleContent>acompanhe as novidades do blog coob+</TitleContent>
            <BtnSeeOffers
              onClick={() =>
                window.open("https://viajantecoobmais.com.br/", "_blanck")
              }
            >
              clique aqui
            </BtnSeeOffers>
          </WrapperItensContent2>
        </WrapperContent>
      </Section>
      <TestimonialSection settings={settingsLastSlider} />
      <PartnerSection itens={partnerList} />
      <CoobrasturGroupSection />
    </Container>
  );
}

export default memo(Home);

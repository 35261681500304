import { useState, useRef, Fragment, useEffect } from "react";
import Modal from "react-modal";
import { Form } from "@unform/web";
import { FormHandles, FormHelpers, FormProps } from "@unform/core";
import Input from "../input";
import * as Yup from "yup";
import { dataInput } from "./constants";

import { ReactComponent as IconFechar } from "../../assets/icones/icons dense (24px)/fechar.svg";
import { ReactComponent as IconInfo } from "../../assets/icones/icons dense (24px)/informação  - black.svg";
import {
  ButtonCloseModal,
  LinkRestPassword,
  Message,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalHeaderTilte,
  SubmitForm,
  Subtitle,
  Text,
  Title,
  TitleForm,
} from "./styles";
import { Errors, MessageProps } from "./types";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { ModalDateAvailableProps } from "../modalDateAvailable/types";
import { api } from "../../services";
import { ModalFormConfirmation } from "../modalFormConfirmation";
import { dataInputForgottPass } from "../header/constants";
import { BtnLoginUser, FormItem } from "../header/styles";
import { FormForgottPassProps } from "../header/types";
import { cpfOrCNPJValidator } from "../../utils";

export const ModalFormAvailability = ({
  modalIsOpen,
  setIsOpen,
  hotel,
}: ModalDateAvailableProps) => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState<MessageProps>({} as MessageProps);
  const formRef = useRef<FormHandles>(null);
  const mobile = useMediaQuery("(max-width: 990px)");
  const token = localStorage.getItem("token");

  const [forgotPassOpen, setForGottPassOpen] = useState<boolean>(false);
  const [errorLogin, setErroLogin] = useState<string>("");
  const [succesLogin, setSuccessLogin] = useState<string>("");
  const formRefForgot = useRef<FormHandles>(null);
  const handleForGotPassword = () => {
    setForGottPassOpen(true);
  };

  const handleSubitforgottPass = async (data: FormForgottPassProps) => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email("campo obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api
        .get(
          `/wsCoobrastur/ConsultaHoteis.asmx/C62_1?email=${data.email}&token=${token}`
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data[0].resultado === "0") {
              setErroLogin(resp.data[0].mensagem);
              setSuccessLogin("");
              return;
            }

            if (resp.data[0].resultado === "1") {
              setErroLogin("");
              setSuccessLogin(resp.data[0].mensagem);
              return;
            }
          }
        });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRefForgot.current?.setErrors(errorMessage);
        });
        return;
      }
      formRefForgot.current?.setErrors({});
    }
  };

  useEffect(() => {
    if (!modalIsOpen) {
      setForGottPassOpen(false);
    }
  }, [modalIsOpen]);

  const submit = async (data: FormProps, { reset }: FormHelpers) => {
    try {
      const schema = Yup.object().shape({
        user: Yup.string()
          .required("campo é obrigatório")
          .test(
            "invalid-cpf",
            "* CPF ou CNPJ inválido. Por favor, tente novamente.",
            (cpf) => cpfOrCNPJValidator(cpf)
          ),
        email: Yup.string()
          .email("e-mail inválido")
          .required("o e-mail é obrigatório"),
        password: Yup.string().required("a senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessage: Errors = {};
        err.inner.forEach((error) => {
          if (error.path) {
            errorMessage[error.path] = error.message;
          }
          return formRef.current?.setErrors(errorMessage);
        });
        return;
      }
    }

    formRef.current?.setErrors({});
    requestAvailability(data, reset);
  };

  const requestAvailability = (
    { user, password, email }: any,
    reset: () => void
  ) => {
    const data = {
      token: token,
      usuario: user,
      senha: password,
      disEmail: email,
      hotCodigo: hotel.hotCodigo,
      tipo: 2,
    };

    api
      .post("/wsCoobrastur/Hotel.asmx/solicitaDisponibilidadeExtra_1", {
        dados: JSON.stringify(data),
      })
      .then((response) => {
        const { data } = response;

        if (data.Table[0].situacao === 1) {
          if (token) {
            localStorage.setItem("tokenDisp", token);
          }

          setMessage({
            title: "Sua solicitação foi enviada ao hotel!",
            body: "Esta solicitação NÃO GARANTE a sua reserva. Acompanhe seu e-mail para seguir os próximos passos da solicitação. Lembre-se de olhar na caixa de SPAM ou Lixo Eletrônico.",
          });
          setModal(true);

          reset();
        } else {
          const body =
            data?.Table?.[0]?.Texto ||
            "Algo deu Errado, A página será redirecionada para o início.";

          setMessage({
            title: "Ops!",
            body,
          });
          setModal(true);

          // Volta para pagina principal
          if (
            body.includes("muito tempo sem navegar") ||
            body.includes("A página será redirecionada para o início")
          ) {
            setTimeout(() => {
              window.location.href = `${process.env.PUBLIC_URL}/`;
            }, 3000);
          }
        }
      })
      .catch(console.error);

    return;
  };

  return (
    <Fragment>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        style={{
          content: {
            width: "95%",
            maxWidth: "482px",
            height: mobile ? "550px" : "490px",
            padding: "0",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            overflow: "hidden",
            zIndex: 999,
            borderRadius: "20px",
          },
          overlay: {
            zIndex: 999,
            backgroundColor: "rgba(0,0,0,0.48)",
          },
        }}
      >
        {!forgotPassOpen ? (
          <ModalContent>
            <ModalHeader>
              <ButtonCloseModal
                type="button"
                title="fechar"
                onClick={() => setIsOpen(!modalIsOpen)}
              >
                <IconFechar />
              </ButtonCloseModal>
              <ModalHeaderTilte>FECHAR</ModalHeaderTilte>
            </ModalHeader>
            <ModalBody>
              <Title>Solicitar disponibilidade em:</Title>
              <Subtitle>{hotel.name}</Subtitle>
              <Text>
                Para enviar a solicitação de disponibilidade para o hotel, você
                precisa preencher os dados a seguir.
              </Text>

              <Form onSubmit={submit} ref={formRef}>
                <TitleForm>Insira seus dados de login</TitleForm>

                {dataInput.map((item) => (
                  <Input
                    name={item.name}
                    label={item.label}
                    key={item.id}
                    id={item.id}
                    description={item.description}
                    type={item.type}
                    maxLength={item?.maxLength}
                    onChange={item?.onChange}
                  />
                ))}

                <LinkRestPassword onClick={handleForGotPassword}>
                  Esqueci minha senha
                </LinkRestPassword>

                <SubmitForm type="submit">SOLICITAR</SubmitForm>

                <Message>
                  <IconInfo />
                  <p>
                    O hotel vai responder sua solicitação direto para o seu
                    e-mail. Lembre-se de olhar na caixa de SPAM ou Lixo
                    Eletrônico
                  </p>
                </Message>
              </Form>
            </ModalBody>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>
              <ButtonCloseModal
                type="button"
                title="voltar"
                onClick={() => setForGottPassOpen(false)}
              >
                <IconFechar />
              </ButtonCloseModal>
              <ModalHeaderTilte>VOLTAR</ModalHeaderTilte>
            </ModalHeader>
            <ModalBody>
              <div style={{ paddingLeft: "16px" }}>
                Insira o endereço de email associado à sua conta que lhe
                enviaremos um link de redefinição de senha.
              </div>

              <FormItem ref={formRefForgot} onSubmit={handleSubitforgottPass}>
                {dataInputForgottPass.map((item) => (
                  <Input
                    name={item.name}
                    label={item.label}
                    key={item.id}
                    id={item.id}
                    description={item.description}
                    type={item.type}
                  />
                ))}
                <div style={{ width: "100%" }}>
                  <span style={{ color: "var(--purple500)" }}>
                    <strong
                      style={{ cursor: "pointer", color: "var(--pink500)" }}
                      onClick={() => setForGottPassOpen(false)}
                    >
                      {" "}
                      Voltar
                    </strong>
                  </span>
                </div>

                {/* <div style={{ width: "100%" }}>
                  <span style={{ color: "var(--purple600)" }}>
                    Primeiro Acesso?
                    <strong
                      style={{ cursor: "pointer", color: "var(--pink500)" }}
                      onClick={() =>
                        console.log("abre state primeiro acesso")
                      }
                    >
                      {" "}
                      Acesse aqui
                    </strong>
                  </span>
                </div> */}
                {succesLogin !== "" && (
                  <span style={{ color: "var(--green600)" }}>
                    {succesLogin}
                  </span>
                )}
                {errorLogin !== "" && (
                  <span style={{ color: "var(--red600)" }}>{errorLogin}</span>
                )}
                <BtnLoginUser type="submit">redefinir senha</BtnLoginUser>
              </FormItem>
            </ModalBody>
          </ModalContent>
        )}
      </Modal>

      <ModalFormConfirmation
        modalIsOpen={modal}
        setIsOpen={setModal}
        message={message}
      />
    </Fragment>
  );
};

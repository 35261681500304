import { ReactComponent as IconLocation } from "../../assets/icones/icons dense (24px)/localização.svg";
import { ReactComponent as IconCalendarIn } from "../../assets/icones/icons dense (24px)/calendario in.svg";
import { ReactComponent as IconCalendarOut } from "../../assets/icones/icons dense (24px)/calendario out.svg";
import { ReactComponent as IconPersona } from "../../assets/icones/icons dense (24px)/pessoas.svg";
import { ReactComponent as IconSearch } from "../../assets/icones/icons dense (24px)/buscar.svg";
import { ReactComponent as IconPlace } from "../../assets/icones/icons minimal (20px)/place.svg";
import { ReactComponent as IconHotel } from "../../assets/icones/icons minimal (20px)/hotel.svg";
import { ReactComponent as IconWarning } from "../../assets/icones/icons dense (24px)/aviso - yellow.svg";
import { ReactComponent as IconCloseWraning } from "../../assets/icones/icons minimal (20px)/close.svg";
import { ReactComponent as IconAdd } from "../../assets/icones/icons minimal (20px)/add.svg";
import { ReactComponent as IconTrash } from "../../assets/icones/icons minimal (20px)/trash.svg";
import { cloneDeep } from "lodash";

import {
  ButtonAddRoom,
  ButtonRemove,
  ButtonSave,
  ButtonSaveDates,
  ButtonSaveRooms,
  ButtonText,
  ButtonWrapper,
  ContainerWarnning,
  Content,
  CustomCalendar,
  FooterMessage,
  Input,
  Label,
  Loading,
  MessageHelp,
  ModalFooter,
  ModalWrapper,
  ResultItem,
  Results,
  ResultSubtitle,
  ResultTtilte,
  Room,
  RoomButtonsGroup,
  RoomContent,
  RoomHeader,
  RoomOptions,
  RoomTitle,
  SearchContent,
  Separator,
  Switch,
  TextWarnning,
  Title,
  WrapperInput,
  WrapperInputCalendar,
  WrapperInputGroup,
} from "./styles";
import {
  Fragment,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  City,
  LocationProps,
  RoomProps,
  MaxAndMinPassengersPerRoom,
} from "./types";
import { api } from "../../services";
import { useDebounce } from "../../hooks/debounceHook";
import useCreateToken from "../../hooks/createToken";
import { useSearchStore } from "../../store/useSearch";
import { formatDate } from "../../utils";
import { CustomButtonSelect } from "../customButtonSelect";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalPolicy } from "../modalPolicy";
import { useFilters } from "../../hooks/useFilters";
import { ModalFormConfirmation } from "../modalFormConfirmation";
import { MessageProps } from "../modalFormAvailability/types";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useUrlParams } from "../../hooks/useUrlParams";

const ID_SEARCH_BAR = "searchBar";
const ID_MODAL_DATES = "modalDates";
const ID_MODAL_ROOMS = "modalRooms";

interface InitialStateProps {
  adducts: number;
  childrens: {
    number: number;
    children: any[];
  };
  limit: number;
}

interface SearchDesktopProps {
  resetFilterPlan?: (item: number) => void;
  initialQuery?: string;
}

const mockInitialStartObj: InitialStateProps = {
  adducts: 2,
  childrens: {
    number: 0,
    children: [],
  },
  limit: 3,
};

const MAX_AND_MIN_PASSENGERS: MaxAndMinPassengersPerRoom = {
  minAdult: 1,
  maxAdult: 3,
  minChildren: 0,
  maxChildren: 1,
  maxTotal: 3,
};

export const SearchDesktop = ({
  resetFilterPlan,
  initialQuery,
}: SearchDesktopProps) => {
  const { urlParams } = useUrlParams();
  const [initialStart, setInitialStart] =
    useState<InitialStateProps>(mockInitialStartObj);
  const [maxAndMinPassengers, setMaxAndMinPassengers] =
    useState<MaxAndMinPassengersPerRoom>(MAX_AND_MIN_PASSENGERS);

  useEffect(() => {
    // Aqui muda se o limite por quarto se tipo de usuario estiver correto
    if (urlParams.tipoUsuario) {
      const INITIAL_START_LIMIT = urlParams.tipoUsuario ? 4 : 3;
      const initialStartObj: RoomProps = {
        adducts: 2,
        childrens: {
          number: 0,
          children: [],
        },
        limit: INITIAL_START_LIMIT,
      };

      const MAX_AND_MIN_PASSENGERS: MaxAndMinPassengersPerRoom = {
        minAdult: 1,
        maxAdult: 4,
        minChildren: 0,
        maxChildren: 2,
        maxTotal: INITIAL_START_LIMIT,
      };

      setInitialStart(initialStartObj);
      setMaxAndMinPassengers(MAX_AND_MIN_PASSENGERS);
      setRooms([initialStartObj]);
    }
  }, [urlParams]);

  const [noDate, setNoDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setData, data: searchStore } = useSearchStore();
  const [query, setQuery] = useState(
    initialQuery || searchStore?.place?.Descricao || ""
  );
  const [searchData, setSearchData] = useState<City[]>([]);
  const { createToken } = useCreateToken();
  const [modalSearch, setModalSearch] = useState(false);

  const [modalDates, setModalDates] = useState(false);
  const [warn, setWarn] = useState(false);
  const [dateIn, setDateIn] = useState<undefined | Date>();
  const [dateOut, setDateOut] = useState<undefined | Date>();
  const [step, setStep] = useState<1 | 2>(1);
  const [messageDates, setMessageDates] = useState<null | string>(null);
  const [days, setDays] = useState<null | string>(null);
  const [toggleModalPolicy, setToggleModalPolicy] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomProps[]>([initialStart]);
  const [modalRooms, setModalRooms] = useState(false);
  const [messagemRoom, setMessagemRoom] = useState<null | string>(null);
  const [order, setOrder] = useState<null | string>(null);
  const [modalMessage, setModalMessage] = useState<MessageProps>(
    {} as MessageProps
  );
  const mobile = useMediaQuery("(max-width: 990px)");

  const scrollToView = useCallback(
    (id: "searchBar" | "modalDates" | "modalRooms") => {
      let searchTo = ID_SEARCH_BAR;

      if (id === "modalDates" && mobile) {
        searchTo = ID_MODAL_DATES;
      }
      if (id === "modalRooms" && mobile) {
        searchTo = ID_MODAL_ROOMS;
      }

      const element = document.querySelector(`#${searchTo}`);
      element?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    [mobile]
  );

  function closeDateModalOnBodyClick(e: any) {
    const isTile = e.target.parentElement.classList.contains(
      "react-calendar__tile"
    )
      ? true
      : false;
    const modalDatesContainer = document.querySelector("#modalDates");
    if (!modalDatesContainer?.contains(e.target)) {
      if (!isTile) setModalDates(false);
    }

    const modalRoomsContainer = document.querySelector("#modalRooms");
    if (!modalRoomsContainer?.contains(e.target)) setModalRooms(false);
  }

  useEffect(() => {
    document.body.addEventListener("click", closeDateModalOnBodyClick);
  }, []);

  useEffect(() => {
    if (modalDates) {
      setModalRooms(false);
      scrollToView("modalDates");
    }
  }, [modalDates, scrollToView]);
  useEffect(() => {
    if (modalRooms) {
      setModalDates(false);
      scrollToView("modalRooms");
    }
  }, [modalRooms, scrollToView]);

  const handleCloseModalFormConfirmation = () => {
    setModalMessage({} as MessageProps);
  };

  const { state }: LocationProps = useLocation();

  const navigate = useNavigate();

  const toggleInput = (e: SyntheticEvent<HTMLInputElement>) => {
    const noDateNewValue: boolean = e.currentTarget.checked;
    setModalDates(false);
    setNoDate(noDateNewValue);
    if (noDateNewValue) {
      setData({ ...searchStore, dateIn: undefined, dateOut: undefined });
    } else {
      setData({ ...searchStore, dateIn, dateOut });
    }
  };

  const { clearAllFilter } = useFilters();

  const handleChage = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setQuery(value);
    setModalSearch(true);
  };

  const handleToggleModalPolicy = () => {
    setToggleModalPolicy(!toggleModalPolicy);
  };

  const loadData = useCallback(() => {
    if (query) {
      const serachData = async () => {
        setLoading(true);
        setSearchData([]);

        if (!query.trim()) {
          setLoading(false);
          setSearchData([]);
          return;
        }

        const token = localStorage.getItem("token");
        if (!!token) {
          api
            .post(`/wsCoobrastur/ConsultaHoteis.asmx/C20_1`, {
              token: token,
              consulta: query,
              iata: false,
            })
            .then((response) => {
              setLoading(false);
              setSearchData(response.data);
            })
            .catch((error) => {
              setLoading(false);
              console.error({ component: "searchDesktop", erro: error });
            });
        } else {
          setLoading(false);
          console.error({ component: "searchDesktop", erro: "NO TOKEN" });
        }
      };

      serachData();
    }
  }, [query]);

  useDebounce(query, 500, loadData);

  const savePlace = (data: City) => {
    setData({ ...searchStore, place: data });
    setQuery(data.Descricao);
    setSearchData([]);
    setModalSearch(false);
    if (noDate) {
      setModalRooms(true);
    } else {
      setModalDates(true);
    }
  };

  const selectDay = (value: Date) => {
    if (step === 1) {
      setDateIn(value);
      localStorage.setItem("dataInicial", formatDate(value)!);
      if (urlParams.tipoUsuario) {
        setDateOut(value);
        localStorage.setItem("dataFinal", formatDate(value)!);
        setStep(2);
        return;
      }
      setDateOut(undefined);
      setStep(2);
    }

    if (step === 2) {
      if (dateIn === undefined) {
        setDateOut(undefined);
        localStorage.setItem("dataFinal", formatDate(value)!);
        setStep(1);
        return;
      }

      if (value.getTime() < dateIn.getTime()) {
        setDateOut(dateIn);
        localStorage.setItem("dataFinal", formatDate(dateIn)!);
        setDateIn(value);
        localStorage.setItem("dataInicial", formatDate(value)!);
        setStep(1);
        return;
      }

      const dayDifference = dateIn.getTime() - new Date().getTime();
      const dayDifferenceInDays = Math.ceil(dayDifference / (1000 * 3600 * 24));

      if (dayDifferenceInDays <= 30) setWarn(true);

      setDateOut(value);
      localStorage.setItem("dataFinal", formatDate(value)!);
      setStep(1);
    }
  };

  const saveDate = () => {
    if (dateIn && dateOut) {
      setData({ ...searchStore, dateIn, dateOut });
    }
    setModalDates(false);
    setModalRooms(true);
  };

  const addRoom = () => {
    if (rooms.length <= initialStart.limit) {
      const newInitialStart = cloneDeep(initialStart);

      const newRooms = [...rooms, newInitialStart];
      setRooms(() => newRooms);
    }
  };

  const removeRoom = (index: number) => {
    const newRooms = [...rooms];
    newRooms.splice(index, 1);

    setRooms(() => newRooms);
  };

  const addAdduct = (index: number) => {
    const newRooms = [...rooms];
    const roomLimit =
      newRooms[index].adducts + newRooms[index].childrens.number;
    if (
      roomLimit < newRooms[index].limit && // roomLimit < MAX_AND_MIN_PASSENGERS.maxTotal &&
      newRooms[index].adducts < maxAndMinPassengers.maxAdult
    ) {
      newRooms[index].adducts += 1;
    }
    setRooms(newRooms);
  };

  const removeAdduct = (index: number) => {
    const newRooms = [...rooms];

    if (newRooms[index].adducts > maxAndMinPassengers.minAdult) {
      newRooms[index].adducts -= 1;
    }

    setRooms(() => newRooms);
  };

  const addChildrens = (index: number) => {
    const newRooms = [...rooms];

    const hasRequired = newRooms[index].childrens.children.filter(
      (children: any) => children.year < 0
    );

    if (hasRequired.length > 0) {
      setMessagemRoom(
        "selecione a <strong>idade</strong> da(s) <strong>criança(s)</strong>"
      );
      return;
    } else {
      setMessagemRoom(null);
    }

    const roomLimit =
      newRooms[index].adducts + newRooms[index].childrens.number;
    if (
      roomLimit < newRooms[index].limit && // roomLimit < maxAndMinPassengers.maxTotal &&
      newRooms[index].childrens.number < maxAndMinPassengers.maxChildren
    ) {
      newRooms[index].childrens.number += 1;
      newRooms[index].childrens.children.push({ year: -1 });
    } else {
      return;
    }

    setMessagemRoom(
      "selecione a <strong>idade</strong> da(s) <strong>criança(s)</strong>"
    );
    setRooms(() => newRooms);
  };

  const removeChildrens = (index: number) => {
    const newRooms = [...rooms];

    if (newRooms[index].childrens.number > maxAndMinPassengers.minChildren) {
      newRooms[index].childrens.number -= 1;
      newRooms[index].childrens.children.pop();
    }

    setMessagemRoom(null);
    setRooms(() => newRooms);
  };

  const handleInput = (event: SyntheticEvent<HTMLInputElement>) => {
    return (indexRoom: number) => {
      return (indexChildren: number) => {
        const { value } = event.currentTarget;
        const newRooms = [...rooms];

        newRooms[indexRoom].childrens.children[indexChildren].year =
          Number(value);

        setRooms(() => newRooms);
        setMessagemRoom(null);
      };
    };
  };

  const numVisitant = useCallback(() => {
    const newObject = [...rooms];

    const number = newObject.reduce((acc, curr) => {
      return (acc += curr.adducts + curr.childrens.number);
    }, 0);

    return number;
  }, [rooms]);

  const saveRooms = () => {
    const number = numVisitant();

    setData({
      ...searchStore,
      numRoom: rooms.length,
      visitant: number,
      rooms: rooms,
    });
    setModalRooms(!modalRooms);
  };

  const handleSearchHotels = () => {
    const { dateIn, dateOut, place, rooms } = searchStore;
    // console.log(dateIn, dateOut)
    // const dataInicial = formatDate(dateIn) || ''
    // const dataFinal = formatDate(dateOut) || ''

    // localStorage.setItem("dataInicial", dataInicial);
    // localStorage.setItem("dataFinal", dataFinal);
    // localStorage.setItem("token", tokenApi);
    // console.log("não troquei o token")

    clearAllFilter();
    if (resetFilterPlan) {
      let plans = document.querySelectorAll("li");

      plans.forEach((item) => {
        if (item !== plans[7]) {
          item.classList.remove("active");
        } else {
          plans[7].classList.add("active");
        }
      });

      resetFilterPlan(-1);
    }

    const errorFields: string[] = [];
    // if (!!rooms) {
    //   rooms.forEach(({ adducts, childrens }, i) => {
    //     if (
    //       adducts < MAX_AND_MIN_PASSENGERS.minAdult ||
    //       adducts > MAX_AND_MIN_PASSENGERS.maxAdult ||
    //       childrens.number < MAX_AND_MIN_PASSENGERS.minChildren ||
    //       childrens.number > MAX_AND_MIN_PASSENGERS.maxChildren ||
    //       childrens.number + adducts > MAX_AND_MIN_PASSENGERS.maxTotal
    //     ) {
    //       errorFields.push(` o número de hóspedes/quartos (dados inválidos no quarto ${i+1})`);
    //     }
    //   });
    // }

    if (query === "" || !place || (!(dateIn && dateOut) && !noDate) || !rooms) {
      if (query === "" || !place) {
        errorFields.push(" nome do local (cidade ou hotel específico)");
      }

      if (!(dateIn && dateOut) && !noDate) {
        errorFields.push(
          ' datas (ou assinale o campo "Ainda não defini as datas")'
        );
      }

      if (!rooms) {
        errorFields.push(" o número de hóspedes/quartos");
      }

      if (errorFields.length > 1) {
        errorFields.splice(errorFields.length - 1, 0, "e");
      }
      setModalMessage({
        title: "Ocorreu um erro ao tentar realizar sua pesquisa",
        body: `Preencha as informações necessárias:${errorFields
          .toString()
          .replace(",e,", " e")}`,
      });
      return;
    }

    if (place.pais !== "BR") {
      if (noDate) {
        setModalMessage({
          title: "Não é possivel consultar hotéis internacionais sem período!",
          body: "Por favor escolha dada de entrada e saída.",
        });
        return;
      }

      navigate(`${process.env.PUBLIC_URL}/hospedagens-internacional`, {
        state: {
          data: searchStore,
        },
      });

      return;
    }

    navigate(`${process.env.PUBLIC_URL}/hospedagens`, {
      state: {
        data: searchStore,
      },
    });
    return;
  };

  useEffect(() => {
    const number = numVisitant();

    setOrder(`${rooms.length} quarto, ${number} hóspedes`);
  }, [rooms, numVisitant]);

  useEffect(() => {
    if (rooms) {
      let required: any | null = null;

      rooms.map((room) => {
        const result = room.childrens.children.filter(
          (children) => children.year < 0
        );

        required = result;

        return room;
      });

      if (required?.length === 0) setMessageDates(null);
    }
  }, [rooms]);

  useEffect(() => {
    if (urlParams.tipoUsuario) {
      if (!dateIn && !dateOut) {
        if (step === 1) {
          setMessageDates("Escolha sua <strong>data de entrada</strong>");
        }

        if (step === 2) {
          setMessageDates("Escolha sua <strong>data de saída</strong>");
        }
      }
    } else {
      if (step === 1) {
        setMessageDates("Escolha sua <strong>data de entrada</strong>");
      }

      if (step === 2) {
        setMessageDates("Escolha sua <strong>data de saída</strong>");
      }
    }
  }, [step]);

  useEffect(() => {
    if (dateIn && dateOut) {
      const diffInMs = Math.abs(dateOut.getTime() - dateIn.getTime());
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

      if (urlParams.tipoUsuario) {
        const correctedDifference = diffInDays === 0 ? 1 : diffInDays;
        setDays(`Estadia de ${correctedDifference} diária(s)`);
        return;
      }
      setMessageDates(diffInDays < 2 ? "Estadia mínima de 2 diárias" : null);
      setDays(`Estadia de ${diffInDays} diária(s)`);
    }
  }, [dateIn, dateOut]);

  useEffect(() => {
    createToken();
  }, [createToken]);

  useEffect(() => {
    if (state?.data && window.location.pathname !== "/") {
      const { data } = state;
      setData({ ...data });
    }
  }, [state, setData]);

  useEffect(() => {
    const { place } = searchStore;
    if (place?.pais) {
      localStorage.setItem("pais", place?.pais);
    }
  }, [searchStore]);

  return (
    <Fragment>
      <ModalPolicy
        isOpen={toggleModalPolicy ? true : false}
        onClose={handleToggleModalPolicy}
      />
      <ModalFormConfirmation
        modalIsOpen={!!modalMessage?.title}
        message={modalMessage}
        setIsOpen={handleCloseModalFormConfirmation}
      />
      <SearchContent id={ID_SEARCH_BAR}>
        <Title>Escolha seu próximo destino!</Title>

        <Content>
          <WrapperInput>
            <Label hasError={modalSearch && (!searchStore?.place || !query)}>
              <IconLocation />
              <input
                type="text"
                name="buscar"
                placeholder="Pesquise por um hotel/cidade"
                onChange={handleChage}
                value={query}
                onFocus={() => scrollToView(ID_SEARCH_BAR)}
                autoComplete="off"
              />
            </Label>
            <ModalWrapper
              className={`${
                (loading || searchData.length > 0) && modalSearch
                  ? "show"
                  : "hidden"
              }`}
            >
              <Loading active={loading}>
                <span></span>
              </Loading>

              <Results>
                {searchData.map((data: any, i: number) => {
                  const name = data.Descricao.split(",");

                  return (
                    <ResultItem
                      key={`${data.CodigoID}-${i}`}
                      onClick={() => savePlace(data)}
                    >
                      {data.Tipo === "Cidade" ? <IconPlace /> : <IconHotel />}
                      <div>
                        <ResultTtilte>{name[0]}</ResultTtilte>
                        <ResultSubtitle>
                          {name[1]}, {name[2]}
                        </ResultSubtitle>
                      </div>
                    </ResultItem>
                  );
                })}
              </Results>
            </ModalWrapper>
          </WrapperInput>

          <WrapperInputCalendar id={ID_MODAL_DATES}>
            <div className="wrapper">
              <WrapperInputGroup>
                <ButtonWrapper
                  disabled={noDate}
                  onClick={() => setModalDates(!modalDates)}
                  hasError={!noDate && step === 1 && modalDates}
                >
                  <IconCalendarIn />
                  <span className={`${!!searchStore?.dateIn ? "active" : ""}`}>
                    {searchStore?.dateIn
                      ? formatDate(searchStore?.dateIn)
                      : "Entrada"}
                  </span>
                </ButtonWrapper>

                <ButtonWrapper
                  disabled={noDate}
                  onClick={() => setModalDates(!modalDates)}
                  hasError={!noDate && step === 2 && modalDates}
                >
                  <IconCalendarOut />
                  <span className={`${!!searchStore?.dateOut ? "active" : ""}`}>
                    {searchStore?.dateOut
                      ? formatDate(searchStore?.dateOut)
                      : "Saída"}
                  </span>
                </ButtonWrapper>
              </WrapperInputGroup>

              <Switch>
                <Input
                  checked={noDate}
                  type="checkbox"
                  onChange={toggleInput}
                />
                <span>Ainda não defini as datas</span>
              </Switch>
            </div>

            <ModalWrapper className={`${modalDates ? "show" : "hidden"}`}>
              <CustomCalendar
                minDate={new Date()}
                selectRange={true}
                onClickDay={selectDay}
                tileDisabled={({ date }) => {
                  const today = new Date();
                  today.setTime(date.getTime());
                  const defaultReturn = date < today;
                  if (!dateIn || (!!dateIn && !!dateOut)) return defaultReturn;

                  const diffInMs = Math.abs(date.getTime() - dateIn.getTime());
                  const diffInDays = Math.ceil(
                    diffInMs / (1000 * 60 * 60 * 24)
                  );
                  return defaultReturn || diffInDays < 2;
                }}
                next2AriaLabel=""
                next2Label={null}
                prev2AriaLabel=""
                prev2Label={null}
              />

              <ContainerWarnning open={warn}>
                <IconWarning />
                <div>
                  <TextWarnning>
                    Reservas feitas pela Coob+ precisam ser feitas com 30 dias
                    de antecedência. Mas não se preocupe, se sua reserva for
                    para entrada com data inferior a 30 dias, você poderá
                    solicitar disponibilidade de vaga aos hotéis diretamente
                    pelo site da Coob+. Mas, essa disponibilidade ficará a
                    critério do hotel aceitar ou não e a Coob+ não poderá
                    interferir.
                  </TextWarnning>
                  <ButtonText onClick={handleToggleModalPolicy}>
                    Dúvida, leia mais detalhes aqui
                  </ButtonText>
                </div>
                <IconCloseWraning onClick={() => setWarn(!warn)} />
              </ContainerWarnning>

              <Separator />

              <FooterMessage>
                {messageDates !== null && (
                  <span
                    dangerouslySetInnerHTML={{ __html: messageDates }}
                  ></span>
                )}

                {messageDates === null && days !== null && (
                  <span
                    className="success"
                    dangerouslySetInnerHTML={{ __html: days }}
                  ></span>
                )}
              </FooterMessage>

              <ButtonSaveDates
                disabled={messageDates !== null}
                onClick={saveDate}
              >
                Selecionar datas
              </ButtonSaveDates>
            </ModalWrapper>
          </WrapperInputCalendar>

          <WrapperInput id={ID_MODAL_ROOMS}>
            <ButtonWrapper
              onClick={() => setModalRooms(!modalRooms)}
              hasError={modalRooms && !searchStore?.rooms}
            >
              <IconPersona />
              <span className={`${!!searchStore?.rooms ? "active" : ""}`}>
                {!searchStore?.rooms
                  ? "quartos e hóspedes"
                  : `${searchStore?.rooms?.length} quarto(s), ${searchStore.visitant} hóspede(s)`}
              </span>
            </ButtonWrapper>

            <ModalWrapper
              className={`modalRooms ${modalRooms ? "show" : "hidden"}`}
            >
              {rooms.map((room, indexRoom) => (
                <Room key={indexRoom.toString()}>
                  <RoomHeader>
                    <RoomTitle>Quarto {indexRoom + 1}</RoomTitle>

                    {indexRoom > 0 && (
                      <ButtonRemove
                        type="button"
                        onClick={() => removeRoom(indexRoom)}
                      >
                        eliminar <IconTrash />
                      </ButtonRemove>
                    )}
                  </RoomHeader>
                  <RoomOptions>
                    <RoomContent>
                      <p>Adultos</p>
                      <sup>Maiores de 18 anos</sup>
                    </RoomContent>
                    <RoomButtonsGroup>
                      <button
                        type="button"
                        onClick={() => removeAdduct(indexRoom)}
                        disabled={room.adducts <= maxAndMinPassengers.minAdult}
                      >
                        -
                      </button>
                      <span>{room.adducts}</span>
                      <button
                        type="button"
                        onClick={() => addAdduct(indexRoom)}
                        disabled={
                          room.adducts >= maxAndMinPassengers.maxAdult ||
                          room.childrens.number + room.adducts >=
                            maxAndMinPassengers.maxTotal
                        }
                      >
                        +
                      </button>
                    </RoomButtonsGroup>
                  </RoomOptions>
                  <RoomOptions>
                    <RoomContent>
                      <p>Crianças</p>
                      <sup>Até 17 anos</sup>
                    </RoomContent>
                    <RoomButtonsGroup>
                      <button
                        type="button"
                        onClick={() => removeChildrens(indexRoom)}
                        disabled={
                          room.childrens.number <=
                          maxAndMinPassengers.minChildren
                        }
                      >
                        -
                      </button>
                      <span>{room.childrens.number}</span>
                      <button
                        type="button"
                        onClick={() => addChildrens(indexRoom)}
                        disabled={
                          room.childrens.number >=
                            maxAndMinPassengers.maxChildren ||
                          room.childrens.number + room.adducts >=
                            maxAndMinPassengers.maxTotal
                        }
                      >
                        +
                      </button>
                    </RoomButtonsGroup>
                  </RoomOptions>

                  {room.childrens.number > 0 && (
                    <MessageHelp>
                      Idades das crianças na data de entrada
                    </MessageHelp>
                  )}

                  {room.childrens.children.map((children, indexChildren) => (
                    <Fragment key={indexChildren.toString()}>
                      <RoomOptions>
                        <RoomContent>
                          <p>Criança {indexChildren + 1}</p>
                        </RoomContent>

                        <CustomButtonSelect
                          children={children}
                          handleInput={handleInput}
                          indexChildren={indexChildren}
                          indexRoom={indexRoom}
                        />
                      </RoomOptions>
                    </Fragment>
                  ))}
                </Room>
              ))}

              {rooms.length < 4 && (
                <ButtonAddRoom type="button" onClick={addRoom}>
                  <IconAdd />
                  Adicionar quarto
                </ButtonAddRoom>
              )}

              <ModalFooter>
                <FooterMessage>
                  {messagemRoom !== null && (
                    <span
                      dangerouslySetInnerHTML={{ __html: messagemRoom }}
                    ></span>
                  )}
                  {messagemRoom === null && order !== null && (
                    <span
                      className="success"
                      dangerouslySetInnerHTML={{ __html: order }}
                    ></span>
                  )}
                </FooterMessage>

                <ButtonSaveRooms
                  disabled={rooms === null || messagemRoom !== null}
                  onClick={saveRooms}
                >
                  SALVAR
                </ButtonSaveRooms>
              </ModalFooter>
            </ModalWrapper>
          </WrapperInput>

          <ButtonSave onClick={handleSearchHotels}>
            <IconSearch />
            <span>Buscar</span>
          </ButtonSave>
        </Content>
      </SearchContent>
    </Fragment>
  );
};

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-height: 100%;
  background-color: var(--purple600);
  color: var(--white);
`;

export const ContentSearch = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.75rem;
  padding: 1rem;
`;

export const ContentSelect = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem;

  & + & {
    margin-top: 0.75rem;
  }

  span {
    font-size: 0.875rem;
    font-weight: 700;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
`;

export const ButtonEdit = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: rgba(0, 0, 0, 0.08);

  svg {
    path {
      fill: var(--white);
    }
  }
`;

export const ContentSearchEdit = styled.div`
  padding: 1rem;
`;

export const ContentSearchEditHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 0.75rem;

  span {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;
  margin-top: 0.65rem;
`;

export const Button = styled.button`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.75rem;
  background-color: var(--white);
  border: none;
  color: var(--black);
  font-size: 0.938rem;
  font-weight: 700;
  border-radius: 20px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  padding: 0 1rem;
  opacity: 1;
  transform: all 0.3s ease;

  span {
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  &:nth-of-type(1) {
    svg {
      g {
        path {
          fill: var(--purple600);
        }
      }
    }
  }

  svg.people {
    g {
      g {
        path {
          fill: var(--purple600);
        }
      }
    }
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export const Switch = styled.label`
  display: grid;
  grid-template-columns: 28px 1fr;
  gap: 0.75rem;
  margin: 0.75rem 0;

  span {
    font-size: 0.875rem;
  }
`;

export const Input = styled.input`
  appearance: none;
  position: relative;
  width: 28px;
  height: 16px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    background-color: var(--gray300);
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 25px;
    background-color: var(--white);
    transition: all 0.3s ease;
  }

  &:checked {
    &::before {
      background-color: var(--pink400);
    }

    &::after {
      background-color: var(--pink600);
      left: calc(100% - 16px);
    }
  }
`;

export const ButtonSearch = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1rem 0 0.45rem;

  background-color: var(--green600);
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;

  border-radius: 20px;
  min-height: 40px;
  max-width: 100%;
  width: 100%;
  border: none;

  svg {
    margin-right: 0.45rem;

    g,
    path {
      stroke: var(--white);
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

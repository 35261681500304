import { CarrouselItemsProps } from "../types";
import BannerCoporate from "../assets/banner-comparativo.png";
import BannerCorporateMobile2 from "../assets/banner-mobile-comparativo2.png";
import BannerInd from "../assets/inidique_banner_home.png";
import BannerIndMobile2 from "../assets/indique_banner_home_mobile.png";
import BannerReserve from "../assets/banner-reserva.png";
import BannerReserveMobile from "../assets/banner-mobile-reserva.png";
import BannerReemb from "../assets/banner-reembolso.png";
import Banner35Desktop from "../assets/BANNER_SITE_35ANOS.png";
import Banner35Mobile from "../assets/BANNER_SITE_35ANOS_mobile.png";

export const CarrouselItems: CarrouselItemsProps[] = [
  {
    id: 15,
    img: Banner35Desktop,
    link: `https://www.coobmais.com.br/lp/35-anos/`,
    device: "DESKTOP",
  },
  {
    id: 16,
    img: Banner35Mobile,
    link: `https://www.coobmais.com.br/lp/35-anos/`,
    device: "MOBILE",
  },
  {
    id: 1,
    img: BannerCoporate,
    link: `https://coobrasturltda.sharepoint.com/:b:/s/EQP-MARKETING-EXT/Ed-Yq9FrN-ZKo69DSfJuweoB7QPZyvHP9-JI0vHPuc9gpA?e=DLb9L2`,
    device: "DESKTOP",
  },
  {
    id: 2,
    img: BannerCorporateMobile2,
    link: `https://coobrasturltda.sharepoint.com/:b:/s/EQP-MARKETING-EXT/Ed-Yq9FrN-ZKo69DSfJuweoB7QPZyvHP9-JI0vHPuc9gpA?e=DLb9L2`,
    device: "MOBILE",
  },
  {
    id: 3,
    img: BannerInd,
    link: `https://www.coobmais.com.br/lp/indiquemais/`,
    device: "DESKTOP",
  },
  {
    id: 4,
    img: BannerIndMobile2,
    link: `https://www.coobmais.com.br/lp/indiquemais/`,
    device: "MOBILE",
  },
  {
    id: 7,
    img: BannerReserve,
    link: `/reservamenos30dias`,
    device: "DESKTOP",
  },
  {
    id: 8,
    img: BannerReserveMobile,
    link: `/reservamenos30dias`,
    device: "MOBILE",
  },
  {
    id: 9,
    img: BannerReemb,
    link: `/reembolsoforadarede`,
    device: "DESKTOP",
  },
];

export const partnerList = [
  {
    id: 1,
    img: `https://www.coobmais.com.br/images/parceiros/sabesp.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisSABESP`,
  },
  {
    id: 2,
    img: `https://www.coobmais.com.br/images/parceiros/ativa.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisATIVA`,
  },
  {
    id: 3,
    img: `https://www.coobmais.com.br/images/parceiros/mkt-club.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=maisVANTAGENS`,
  },

  {
    id: 4,
    img: `https://www.coobmais.com.br/images/parceiros/fiel-torcedor.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisFIEL`,
  },
  {
    id: 5,
    img: `https://www.coobmais.com.br/images/parceiros/banco-Pan.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=PANmaisTUR`,
  },
  {
    id: 6,
    img: `https://www.coobmais.com.br/images/parceiros/bradesco.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=BRADESCOSEGUROSmaisTUR`,
  },
  {
    id: 7,
    img: `https://www.coobmais.com.br/images/parceiros/C6-bank.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=C6BANKmaisTUR`,
  },

  {
    id: 8,
    img: `https://www.coobmais.com.br/images/parceiros/clubeben.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisCLUBEBEN`,
  },
  {
    id: 9,
    img: `https://www.coobmais.com.br/images/parceiros/elegibilidade.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisELEG`,
  },
  {
    id: 10,
    img: `https://www.coobmais.com.br/images/parceiros/porto-plus.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=PORTOmaisTUR`,
  },
  {
    id: 11,
    img: `https://www.coobmais.com.br/images/parceiros/rede-parcerias.png`,
    // link: `https://www.coobmais.com.br/ecommerce/?cp=COOBmaisREDE`,
  },
];

export const settingsCarouselBanner = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  arrows: false,
  draggable: false,
  adaptiveHeight: true,
};

export const settingsCarouselOffers = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  draggable: false,

  arrows: false,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const settingsLastSlider = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: true,
  adaptiveHeight: true,
  variableWidth: true,
  centerMode: false,
  draggable: false,
  arrows: true,
  // responsive: [
  //   {
  //     breakpoint: 1280,
  //     settings: {
  //       slidesToShow: 3,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 768,
  //     settings: {
  //       slidesToShow: 2,
  //       slidesToScroll: 1,
  //     },
  //   },
  //   {
  //     breakpoint: 500,
  //     settings: {
  //       slidesToShow: 1,
  //       slidesToScroll: 1,
  //       dots: false,
  //     },
  //   },
  // ],
};

export const numberOFRooms = [
  {
    room: 1,
    quantityAdults: 0,
    quantityChildrens: 0,
  },
];

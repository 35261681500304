import { Calendar } from "react-calendar";
import styled, { keyframes } from "styled-components";
import prevDobleArrow from "../../assets/icones/icons minimal (20px)/seta esquerda dupla.svg";
import prevArrow from "../../assets/icones/icons minimal (20px)/seta_esquerda.svg";
import nextDobleArrow from "../../assets/icones/icons minimal (20px)/seta direita dupla.svg";
import nextvArrow from "../../assets/icones/icons minimal (20px)/seta_direita.svg";
import { ContainerWarnningProps, fieldError, LoadingProps } from "./types";

export const SearchContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--purple600);
  color: var(--white);
  border-radius: 32px;
  padding: 1.25rem;
  margin-top: 30px;
  scroll-margin: 80px;

  @media screen and (max-width: 990px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
  }
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 1rem;

  @media screen and (max-width: 990px) {
    max-width: 265px;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 975px;
  margin: 0 auto;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
  }
`;

export const WrapperInput = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  scroll-margin: 100px;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.75rem;
  }
`;

export const ModalWrapper = styled.div`
  position: absolute;
  top: 115%;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 100%;
  background-color: var(--white);
  color: var(--black);
  border-radius: 25px;
  max-height: 408px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 5px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.16);
  scroll-behavior: smooth;
  min-height: 200px;

  &.hidden {
    display: none;
  }

  &.show {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.modalRooms {
    min-width: 365px;
    padding: 0 0.75rem;
    border-radius: 8px;
    scrollbar-width: auto;
    scrollbar-gutter: stable;
    scrollbar-color: var(--purple300) var(--gray500Opacity);

    &::-webkit-scrollbar {
      display: unset;
      width: 14px;
      border-radius: 8px;
      background-color: var(--gray500Opacity);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--purple300);
      border-radius: 8px;
    }
  }

  @media screen and (max-width: 500px) {
    &.modalRooms {
      min-width: unset;
    }
  }
`;

export const Label = styled.label<fieldError>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  flex-grow: 1;
  width: 100%;
  height: 40px;
  border-radius: 25px;
  border: 2px solid
    ${({ hasError }) => (!!hasError ? "var(--red500)" : "var(--white)")};
  padding: 0 0.75rem;

  svg {
    margin-right: 0.45rem;
  }

  input {
    flex-grow: 1;
    padding: 0;
    margin: 0;
    border: none;
    outline: 0;
    font-size: 0.938rem;
    font-weight: 700;
    line-height: 1;
    color: var(--black);

    &::placeholder {
      font-size: 0.938rem;
      font-weight: 400;
      line-height: 1;
      color: var(--gray600);
    }
  }
`;

export const WrapperInputCalendar = styled.div`
  position: relative;
  margin: 0 0.75rem;
  scroll-margin: 100px;

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 0.75rem;
  }
`;

export const WrapperInputGroup = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const ButtonWrapper = styled.button<fieldError>`
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  flex-grow: 1;
  width: 100%;
  min-width: 136px;
  height: 40px;
  border-radius: 25px;
  border: 2px solid
    ${({ hasError }) => (!!hasError ? "var(--red500)" : "var(--white)")};
  padding: 0 0.75rem;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }

  & + & {
    margin-left: 0.75rem;
  }

  svg {
    margin-right: 0.45rem;
  }

  span {
    display: block;
    font-size: 0.938rem;
    line-height: 1;
    color: var(--gray600);

    &.active {
      font-weight: 700;
      color: var(--black);
    }
  }
`;

export const Switch = styled.label`
  display: grid;
  grid-template-columns: 28px 1fr;
  align-items: center;
  gap: 0.75rem;
  margin: 0.75rem 0 0;

  span {
    font-size: 0.875rem;
  }
`;

export const Input = styled.input`
  appearance: none;
  position: relative;
  width: 28px;
  height: 16px;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    background-color: var(--gray300);
    transition: all 0.3s ease;
  }

  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 25px;
    background-color: var(--white);
    transition: all 0.3s ease;
  }

  &:checked {
    &::before {
      background-color: var(--pink400);
    }

    &::after {
      background-color: var(--pink600);
      left: calc(100% - 16px);
    }
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--green600);

  min-width: 132px;
  height: 40px;
  border-radius: 25px;
  border: 2px solid var(--green600);
  padding: 0 0.75rem;
  margin-left: 0.75rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--green500);
    border-color: var(--green500);
  }

  svg {
    margin-right: 0.35rem;

    g {
      stroke: var(--white);
    }

    path {
      stroke: var(--white);
    }
  }

  span {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: var(--white);
    text-transform: uppercase;
  }

  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    justify-content: center;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div<LoadingProps>`
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  justify-content: center;

  span {
    width: 45px;
    height: 45px;
    border-radius: 60%;
    border: 6px solid #c9c8ea;
    border-top-color: #3a2e83;
    animation: ${rotate} 1s linear infinite;
  }
`;

export const Results = styled.ul`
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ResultItem = styled.li`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr;
  align-self: start;
  gap: 0.75rem;
  width: 100%;
  max-width: 100%;
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
  padding-bottom: 0.75rem;

  & + & {
    margin-top: 0.75rem;
  }
`;

export const ResultTtilte = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--black);
  margin: 0;
`;

export const ResultSubtitle = styled.p`
  font-size: 0.75rem;
  color: var(--black);
  margin: 0;
`;

export const CustomCalendar = styled(Calendar)`
  border: 0;
  max-height: 285px;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0 0.75rem;

  .react-calendar {
    &__navigation {
      margin: 0;
      align-items: center;

      &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        font-size: 1.8rem;
        font-weight: 700;
        color: var(--purple600);
        border-radius: 50%;
        padding: 5px;
        margin: 0;
      }

      &__prev2-button {
        content: url("${prevDobleArrow}");
      }

      &__prev-button {
        content: url("${prevArrow}");
      }

      &__label {
        &:enabled,
        &:focus,
        &:hover {
          background-color: transparent !important;
        }

        &__labelText {
          font-family: "Mangueira", "Poppins", sans-serif;
          font-size: 0.875rem;
          color: var(--purple600);
        }
      }

      &__next-button {
        content: url("${nextvArrow}");
      }

      &__next2-button {
        content: url("${nextDobleArrow}");
      }
    }

    &__viewContainer {
      .react-calendar__month-view {
        &__weekdays {
          margin-bottom: 2px;

          &__weekday {
            padding: 2px;
            overflow: inherit !important;

            abbr {
              position: relative;
              font-family: "Mangueira", "Poppins", sans-serif;
              font-size: 0.75rem;
              font-weight: 400;
              color: var(--black);
              text-decoration: none;

              &::before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--black);

                position: absolute;
                left: 0;
                top: 100%;
              }
            }
          }
        }

        &__days {
          display: grid !important;
          grid-template-columns: repeat(7, 1fr);
          align-items: center;
          justify-items: center;
          gap: 4px;

          &__day {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 30px;
            border-radius: 4px;
            padding: 0;

            :disabled {
              background-color: transparent;

              abbr {
                color: var(--gray400);
              }
            }

            abbr {
              font-family: "Mangueira", "Poppins", sans-serif;
              font-size: 1.125rem;
              font-weight: 700;
              color: var(--purple600);
              margin-top: 2px;
            }
          }
        }

        .react-calendar__tile {
          &--now {
            background-color: transparent;

            abbr {
              color: var(--purple600);
            }
          }

          &--active {
            background-color: var(--purple600);
            abbr {
              color: var(--white);
            }
          }
        }
      }
    }
  }
`;

export const ContainerWarnning = styled.div<ContainerWarnningProps>`
  grid-template-columns: repeat(3, auto);
  gap: 8px;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  background-color: var(--orange200);
  border-radius: 8px;
  padding: 0.75rem;
  opacity: ${({ open }) => (open ? "1" : "0")};
  display: ${({ open }) => (open ? "grid" : "none")};
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;

  svg {
    &:nth-of-type(1) {
      path {
        fill: var(--white);
      }
    }

    &:nth-of-type(2) {
      cursor: pointer;
      transform: translate(3px, -3px);
    }
  }
`;

export const TextWarnning = styled.p`
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin: 0;
  color: var(--white);
`;

export const ButtonText = styled.button`
  cursor: pointer;
  display: inline-block;
  background: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;

  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: left;
  text-decoration: underline;
  margin: 0;
  color: var(--white);
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: var(--gray400);
  opacity: 0.32;
  border: none;

  @media screen and (max-width: 990px) {
    margin: 0.75rem 0;
  }
`;

export const FooterMessage = styled.p`
  font-size: 1rem;
  text-align: center;
  color: var(--red500);
  margin: 0;
  margin-bottom: 12px;

  .success {
    color: var(--purple600);
    font-weight: 700;
  }
`;

export const ButtonSaveDates = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 328px;
  min-height: 40px;
  margin: 0 auto;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const Room = styled.div`
  border-bottom: 1px solid rgba(218, 218, 218, 0.24);
  padding-bottom: 0.75rem;
`;

export const RoomHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.75rem;
`;

export const ButtonRemove = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  background: none;
  outline: none;
  border: none;
  color: var(--gray500);

  svg {
    margin-left: 0.35rem;
  }
`;

export const RoomTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--gray500);
  margin: 0.75rem 0;
`;

export const RoomOptions = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.75rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const RoomContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: var(--purple600);
    margin: 0;
  }

  sup {
    font-size: 0.75rem;
    line-height: 1;
    color: var(--purple600);
  }
`;

export const RoomButtonsGroup = styled.div`
  width: 154px;
  display: grid;
  grid-template-columns: 1fr 43px 1fr;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray400);
  border-radius: 25px;
  padding: 4px;

  button {
    cursor: pointer;
    display: block;
    background: none;
    border: none;
    outline: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--gray600);

    &:active,
    &:focus,
    &:hover {
      color: var(--purple600);
    }

    &:disabled {
      color: var(--gray400);
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: var(--purple600);
    text-align: center;
    border-left: 1px solid var(--gray400);
    border-right: 1px solid var(--gray400);
  }
`;

export const MessageHelp = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1;
  color: var(--gray500);
  margin: 0.75rem 0;
`;

export const ButtonAddRoom = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 175px;
  min-height: 36px;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid var(--gray400);
  outline: none;
  margin: 1rem 0;
  padding: 0 0.75rem;

  font-size: 0.875rem;
  font-weight: 500;
  color: var(--gray500);
  transition: all 0.3s ease;

  &:hover {
    color: var(--purple600);

    svg {
      path {
        fill: var(--purple600);
      }
    }
  }

  svg {
    margin-right: 0.45rem;
    path {
      fill: var(--gray500);
      transition: all 0.3s ease;
    }
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid var(--gray400);
  padding: 1rem;
`;

export const ButtonSaveRoom = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 328px;
  min-height: 40px;
  margin: 0 auto;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

export const ButtonSaveRooms = styled.button`
  cursor: pointer;
  display: block;
  background-color: var(--pink600);
  border: none;
  color: var(--white);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  border-radius: 20px;
  width: 100%;
  max-width: 328px;
  min-height: 40px;
  margin: 0 auto;
  transition: all 0.3s ease;

  &:disabled {
    opacity: 0.15;

    &:hover {
      background-color: var(--pink600);
    }
  }

  &:hover {
    background-color: var(--pink500);
  }
`;

import { ReactComponent as IconLocation } from "../../assets/icones/icons dense (24px)/localização - white.svg";
import { ReactComponent as IconPeople } from "../../assets/icones/icons dense (24px)/pessoas - white.svg";
import { ReactComponent as IconCalendar } from "../../assets/icones/icons dense (24px)/calendario in - white.svg";
import { ReactComponent as IconCalendarOut } from "../../assets/icones/icons dense (24px)/calendario out.svg";
import { ReactComponent as IconCalendarIn } from "../../assets/icones/icons dense (24px)/calendario in.svg";
import { ReactComponent as IconEdit } from "../../assets/icones/icons dense (24px)/edit.svg";
import { ReactComponent as IconClose } from "../../assets/icones/icons dense (24px)/fechar - white.svg";
import { ReactComponent as IconSearch } from "../../assets/icones/icons dense (24px)/buscar.svg";

import {
  Button,
  ButtonClose,
  ButtonEdit,
  ButtonGroup,
  Container,
  ContentSearch,
  ContentSearchEdit,
  ContentSearchEditHeader,
  ContentSelect,
  Switch,
  Input,
  ButtonSearch,
} from "./styles";
import { Fragment, useState, useEffect, SyntheticEvent } from "react";

import { ModalRooms } from "../modalRooms";
import { useSearchStore } from "../../store/useSearch";
import { formatDate } from "../../utils";
import { ModalDates } from "../modalDates";
import { ModalPlace } from "../modalPlace";
import { ModalFormConfirmation } from "../modalFormConfirmation";
import { MessageProps } from "../modalFormAvailability/types";
import { useNavigate, useLocation } from "react-router-dom";

interface SearchProps {
  resetFilterPlan?: (item: number) => void;
}

export const Search = ({ resetFilterPlan }: SearchProps) => {
  const [edit, setEdit] = useState(false);
  const [noDate, setNoDate] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [modalDates, setModalDates] = useState(false);
  const [modalRooms, setModalRooms] = useState(false);
  const { setData, data: searchStore } = useSearchStore();
  const [dateIn, setDateIn] = useState<undefined | Date>();
  const [dateOut, setDateOut] = useState<undefined | Date>();
  const [step, setStep] = useState<1 | 2>(1);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [modalMessage, setModalMessage] = useState<MessageProps>(
    {} as MessageProps
  );

  const handleCloseModalFormConfirmation = () => {
    setModalMessage({} as MessageProps);
  };

  const toggleEdit = () => setEdit(!edit);
  const handleCloseModalDates = () => {
    setModalDates(false);
    if (!dateOut) {
      setDateIn(undefined);
      setDateOut(undefined);
      setStep(1);
    }
  };

  const toggleInput = (e: SyntheticEvent<HTMLInputElement>) => {
    const noDateNewValue: boolean = e.currentTarget.checked;
    setNoDate(noDateNewValue);
    if (noDateNewValue) {
      setData({ ...searchStore, dateIn: undefined, dateOut: undefined });
    } else {
      if (dateIn && dateOut) {
        setData({ ...searchStore, dateIn, dateOut });
      } else {
        setDateIn(undefined);
        setDateOut(undefined);
        setStep(1);
      }
    }
  };

  const redirect = () => {
    const { dateIn, dateOut, place, rooms } = searchStore;

    if (resetFilterPlan) {
      let plans = document.querySelectorAll("li");

      plans.forEach((item, index) => {
        if (index === 6) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      });
      resetFilterPlan(-1);
    }
    if (!place || (!(dateIn && dateOut) && !noDate) || !rooms) {
      const errorFields: string[] = [];
      if (!place) {
        errorFields.push(" nome do local (cidade ou hotel específico)");
      }

      if (!(dateIn && dateOut) && !noDate) {
        errorFields.push(
          ' datas (ou assinale o campo "Ainda não defini as datas")'
        );
      }

      if (!rooms) {
        errorFields.push(" o número de hóspedes/quartos");
      }

      if (errorFields.length > 1) {
        errorFields.splice(errorFields.length - 1, 0, "e");
      }
      setModalMessage({
        title: "Ocorreu um erro ao tentar realizar sua pesquisa",
        body: `Preencha as informações necessárias:${errorFields
          .toString()
          .replace(",e,", " e")}`,
      });
      return;
    }

    if (place.pais !== "BR") {
      if (noDate) {
        setModalMessage({
          title: "Não é possivel consultar hotéis internacionais sem período!",
          body: "Por favor escolha dada de entrada e saída.",
        });
        return;
      }

      navigate(`${process.env.PUBLIC_URL}/hospedagens-internacional`, {
        state: {
          data: searchStore,
        },
      });

      return;
    }

    navigate(`${process.env.PUBLIC_URL}/hospedagens`, {
      state: {
        data: searchStore,
      },
    });
    return;
  };

  useEffect(() => {
    if (!state?.data) {
      setEdit(true);
    }
  }, [state]);

  return (
    <Fragment>
      <Container>
        {!edit && (
          <ContentSearch>
            <div>
              <ContentSelect>
                <IconLocation />
                <span>{state?.data?.place?.Descricao}</span>
              </ContentSelect>
              <ContentSelect>
                <IconCalendar />
                <span>
                  {formatDate(state?.data.dateIn)} {" - "}
                  {formatDate(state?.data.dateOut)}
                </span>
              </ContentSelect>
              <ContentSelect>
                <IconPeople />
                <span>
                  {state?.data.numRoom} quarto(s), {state?.data.visitant}{" "}
                  hóspede(s)
                </span>
              </ContentSelect>
            </div>
            <ButtonEdit type="button" title="editar" onClick={toggleEdit}>
              <IconEdit />
            </ButtonEdit>
          </ContentSearch>
        )}

        {edit && (
          <ContentSearchEdit>
            <ContentSearchEditHeader>
              <span>EDITAR BUSCA</span>
              <ButtonClose type="button" title="fechar" onClick={toggleEdit}>
                <IconClose />
              </ButtonClose>
            </ContentSearchEditHeader>

            <div>
              <Button onClick={() => setModalSearch(!modalSearch)}>
                <IconLocation />
                <span>
                  {searchStore.place?.Descricao ||
                    "Pesquise por um hotel/cidade"}
                </span>
              </Button>

              <ButtonGroup>
                <Button
                  disabled={noDate}
                  onClick={() => setModalDates(!modalDates)}
                >
                  <IconCalendarIn />
                  <span>{formatDate(searchStore.dateIn) || "Entrada"}</span>
                </Button>
                <Button
                  disabled={noDate}
                  onClick={() => setModalDates(!modalDates)}
                >
                  <IconCalendarOut />
                  <span>{formatDate(searchStore.dateOut) || "Saída"}</span>
                </Button>
              </ButtonGroup>

              <div>
                <Switch>
                  <Input
                    checked={noDate}
                    type="checkbox"
                    onChange={toggleInput}
                  />
                  <span>Ainda não definiu as datas</span>
                </Switch>
              </div>

              <Button onClick={() => setModalRooms(!modalRooms)}>
                <IconPeople className="people" />
                <span>
                  {searchStore.numRoom && searchStore.visitant
                    ? `${searchStore.numRoom} quarto(s), ${searchStore.visitant} hóspede(s)`
                    : "Escolha o número de quartos"}
                </span>
              </Button>

              <ButtonSearch type="button" onClick={redirect}>
                <IconSearch />
                <span>Buscar</span>
              </ButtonSearch>
            </div>
          </ContentSearchEdit>
        )}
        <ModalFormConfirmation
          modalIsOpen={!!modalMessage?.title}
          message={modalMessage}
          setIsOpen={handleCloseModalFormConfirmation}
        />
      </Container>

      <div>
        <ModalPlace modalSearch={modalSearch} setModalSearch={setModalSearch} />

        <ModalDates
          visible={modalDates}
          onClose={handleCloseModalDates}
          dateIn={dateIn}
          setDateIn={setDateIn}
          dateOut={dateOut}
          setDateOut={setDateOut}
          step={step}
          setStep={setStep}
        />

        <ModalRooms modalRooms={modalRooms} setModalRooms={setModalRooms} />
      </div>
    </Fragment>
  );
};
